export const propertyTypeOptions = [
    { value: 'apartment', label: 'ბინა, აპარტამენტი' },
    { value: 'penthouse', label: 'პენტჰაუსი' },
    { value: 'house', label: 'კერძო სახლი - აგარაკი' },
    { value: 'townhouse', label: 'თაუნ ჰაუსი' },
    { value: 'comercial', label: 'კომერციული ფართი' },
    { value: 'office', label: 'საოფისე ფართი' },
    { value: 'hotel', label: 'სასტუმრო' },
    { value: 'businesscenter', label: 'ბიზნეს ცენტრი' },
    { value: 'land', label: 'მიწა' },
    { value: 'business', label: 'ბიზნესი - ინვესტიცია' },
    { value: 'farm', label: 'ფერმა' },
];

export const transactionTypeOptions = [
    { value: 'sale', label: 'იყიდება' },
    { value: 'installment', label: 'იყიდება განვადებით' },
    { value: 'rent', label: 'ქირავდება' },
    { value: 'dailyrent', label: 'ქირავდება დღიურად' },
    { value: 'pawn', label: 'გირავდება' },
    { value: 'pawnwithowner', label: 'გირავდება ცხოვრების უფლებით' },
    { value: 'auction', label: 'იყიდება აუციონით' },
    { value: 'change', label: 'იცვლება' },
];


export const propertyStatusOptions = [
    { value: 'new', label: 'ახალი აშენებული' },
    { value: 'underconstruction', label: 'მშენებარე' },
    { value: 'old', label: 'ძველი აშენებული' },
    { value: 'hystorical', label: 'ისტრიული შენობა' },
];

export const propertyProjectOptions = [
    { value: 'nonstandard', label: 'არასტანდარტული' },
    { value: 'czechia', label: 'ჩეხური' },
    { value: 'urban', label: 'ქალაქური' },
    { value: 'khrushchev', label: 'ხრუშოვი' },
    { value: 'kavlashvili', label: 'ყავლაშვილი' },
    { value: 'tukharuli', label: 'თუხარული' },
    { value: 'moscow', label: 'მოსკოვი' },
    { value: 'lvov', label: 'ლვოვი' },
    { value: 'tbilisi', label: 'თბილისური ეზო' },
    { value: 'old', label: 'ძველი რემონტი' },
    { value: 'repair', label: 'სარემონტო' },
];

export const propertyProjectClassOptions = [
    { value: 'premiumbusiness', label: 'პრემიუმ ბიზნეს კლასი' },
    { value: 'premium', label: 'პრემიუმ კლასი' },
    { value: 'medium', label: 'მედიუმ კლასი' },
    { value: 'econom', label: 'ეკონომ კლასი' },
];

export const propertyConditionOptions = [
    { value: 'newly', label: 'ახალი გარემონტებული' },
    { value: 'modern', label: 'თანამედროვე რემონტი (Lux)' },
    { value: 'ongoing', label: 'მიმდინარე რემონტი' },
    { value: 'white', label: 'თეთრი რემონტი' },
    { value: 'greenframe', label: 'მწვანე კარკასი' },
    { value: 'whiteframe', label: 'თეთრი კარკასი' },
    { value: 'greyframe', label: 'რუხი კარკასი' },
    { value: 'blackframe', label: 'შავი კარკასი' },
    { value: 'underconstruction', label: 'მშენებარე' },
];

export const cityOptions = [
    { value: 'tbilisi', label: 'თბილისი' },
    { value: 'batumi', label: 'ბათუმი' },
    { value: 'kutaisi', label: 'ქუთაისი' },
    { value: 'rustavi', label: 'რუსთავი' },
    { value: 'abastumani', label: 'აბასთუმანი' },
    { value: 'abasha', label: 'აბაშა' },
    { value: 'agara', label: 'აგარა' },
    { value: 'adigeni', label: 'ადიგენი' },
    { value: 'algeti', label: 'ალგეთი' },
    { value: 'ambrolauri', label: 'ამბროლაური' },
    { value: 'anaklia', label: 'ანაკლია' },
    { value: 'ananuri', label: 'ანანური' },
    { value: 'aspindza', label: 'ასპინძა' },
    { value: 'akhaldaba', label: 'ახალდაბა' },
    { value: 'akhalgori', label: 'ახალგორი' },
    { value: 'akhalkalaki', label: 'ახალქალაქი' },
    { value: 'akhaltsikhe', label: 'ახალციხე' },
    { value: 'akhmeta', label: 'ახმეტა' },
    { value: 'bakuriani', label: 'ბაკურიანი' },
    { value: 'bandza', label: 'ბანძა' },
    { value: 'baghdati', label: 'ბაღდათი' },
    { value: 'bakhmaro', label: 'ბახმარო' },
    { value: 'boboqvati', label: 'ბობოყვათი' },
    { value: 'bodbe', label: 'ბოდბე' },
    { value: 'bolnisi', label: 'ბოლნისი' },
    { value: 'borjomi', label: 'ბორჯომი' },
    { value: 'bulachauri', label: 'ბულაჩაური' },
    { value: 'gamarjveba', label: 'გამარჯვება' },
    { value: 'gardabani', label: 'გარდაბანი' },
    { value: 'geguti', label: 'გეგუთი' },
    { value: 'gombori', label: 'გომბორი' },
    { value: 'gonio', label: 'გონიო' },
    { value: 'gori', label: 'გორი' },
    { value: 'gremi', label: 'გრემი' },
    { value: 'grigoleti', label: 'გრიგოლეთი' },
    { value: 'gudauri', label: 'გუდაური' },
    { value: 'gulgula', label: 'გულგულა' },
    { value: 'gurjaani', label: 'გურჯაანი' },
    { value: 'danispiruli', label: 'დანისპირული' },
    { value: 'dedoplistsqaro', label: 'დედოფლისწყარო' },
    { value: 'dilikauri', label: 'დილიკაური' },
    { value: 'dmanisi', label: 'დმანისი' },
    { value: 'doesi', label: 'დოესი' },
    { value: 'dusheti', label: 'დუშეთი' },
    { value: 'eniseli', label: 'ენისელი' },
    { value: 'vazisubani', label: 'ვაზისუბანი' },
    { value: 'vani', label: 'ვანი' },
    { value: 'vartsikhe', label: 'ვარციხე' },
    { value: 'velistsikhe', label: 'ველისციხე' },
    { value: 'zana', label: 'ზანა' },
    { value: 'zedazeni', label: 'ზედაზენი' },
    { value: 'zestaponi', label: 'ზესტაფონი' },
    { value: 'zugdidi', label: 'ზუგდიდი' },
    { value: 'tetritsqlebi', label: 'თეთრი წყლები' },
    { value: 'tetritsqaro', label: 'თეთრიწყარო' },
    { value: 'telavi', label: 'თელავი' },
    { value: 'terjola', label: 'თერჯოლა' },
    { value: 'tianeti', label: 'თიანეთი' },
    { value: 'igoeti', label: 'იგოეთი' },
    { value: 'iqalto', label: 'იყალთო' },
    { value: 'kavtiskhevi', label: 'კავთისხევი' },
    { value: 'kazreti', label: 'კაზრეთი' },
    { value: 'kardenakhi', label: 'კარდენახი' },
    { value: 'kaspi', label: 'კასპი' },
    { value: 'kechkhobi', label: 'კეჩხობი' },
    { value: 'kvariati', label: 'კვარიათი' },
    { value: 'lagodekhi', label: 'ლაგოდეხი' },
    { value: 'lanchkhuti', label: 'ლანჩხუთი' },
    { value: 'likani', label: 'ლიკანი' },
    { value: 'manavi', label: 'მანავი' },
    { value: 'manglisi', label: 'მანგლისი' },
    { value: 'marneuli', label: 'მარნეული' },
    { value: 'martvili', label: 'მარტვილი' },
    { value: 'martqopi', label: 'მარტყოფი' },
    { value: 'matani', label: 'მატანი' },
    { value: 'makhinjauri', label: 'მახინჯაური' },
    { value: 'mestia', label: 'მესტია' },
    { value: 'metekhi', label: 'მეტეხი' },
    { value: 'misaktsieli', label: 'მისაქციელი' },
    { value: 'mukuzani', label: 'მუკუზანი' },
    { value: 'mukhrani', label: 'მუხრანი' },
    { value: 'mtskheta', label: 'მცხეთა' },
    { value: 'mtsvanekontskhi', label: 'მწვანე კონცხი' },
    { value: 'natanebi', label: 'ნატანები' },
    { value: 'natakhtari', label: 'ნატახტარი' },
    { value: 'ninotsminda', label: 'ნინოწმინდა' },
    { value: 'norio', label: 'ნორიო' },
    { value: 'noste', label: 'ნოსტე' },
    { value: 'nunisi', label: 'ნუნისი' },
    { value: 'odishi', label: 'ოდიში' },
    { value: 'ozurgeti', label: 'ოზურგეთი' },
    { value: 'okami', label: 'ოკამი' },
    { value: 'oni', label: 'ონი' },
    { value: 'pankisi', label: 'პანკისი' },
    { value: 'pirvelimaisi', label: 'პირველი მაისი' },
    { value: 'zhinvali', label: 'ჟინვალი' },
    { value: 'rukhi', label: 'რუხი' },
    { value: 'sagarejo', label: 'საგარეჯო' },
    { value: 'sadakhlo', label: 'სადახლო' },
    { value: 'sazano', label: 'საზანო' },
    { value: 'sairme', label: 'საირმე' },
    { value: 'samtavisi', label: 'სამთავისი' },
    { value: 'samtredia', label: 'სამტრედია' },
    { value: 'sartichala', label: 'სართიჭალა' },
    { value: 'sarpi', label: 'სარფი' },
    { value: 'sachkhere', label: 'საჩხერე' },
    { value: 'senaki', label: 'სენაკი' },
    { value: 'sviri', label: 'სვირი' },
    { value: 'simoneti', label: 'სიმონეთი' },
    { value: 'sioni', label: 'სიონი' },
    { value: 'sighnaghi', label: 'სიღნაღი' },
    { value: 'surami', label: 'სურამი' },
    { value: 'supsa', label: 'სუფსა' },
    { value: 'tibaani', label: 'ტიბაანი' },
    { value: 'tqibuli', label: 'ტყიბული' },
    { value: 'ureki', label: 'ურეკი' },
    { value: 'utsera', label: 'უწერა' },
    { value: 'ujarma', label: 'უჯარმა' },
    { value: 'pasanauri', label: 'ფასანაური' },
    { value: 'poti', label: 'ფოთი' },
    { value: 'pshaveli', label: 'ფშაველი' },
    { value: 'kareli', label: 'ქარელი' },
    { value: 'keda', label: 'ქედა' },
    { value: 'kvishkheti', label: 'ქვიშხეთი' },
    { value: 'kvemoaranisi', label: 'ქვემო არანისი' },
    { value: 'kobuleti', label: 'ქობულეთი' },
    { value: 'ksani', label: 'ქსანი' },
    { value: 'kubriantkari', label: 'ქუბრიანთკარი' },
    { value: 'kveseti', label: 'კვესეთი' },
    { value: 'qazbegi', label: 'ყაზბეგი' },
    { value: 'qvareli', label: 'ყვარელი' },
    { value: 'qulevi', label: 'ყულევი' },
    { value: 'shatili', label: 'შატილი' },
    { value: 'shekvetili', label: 'შეკვეთილი' },
    { value: 'shilda', label: 'შილდა' },
    { value: 'shindisi', label: 'შინდისი' },
    { value: 'shiomghvime', label: 'შიომღვიმე' },
    { value: 'shovi', label: 'შოვი' },
    { value: 'shorapani', label: 'შორაპანი' },
    { value: 'shuamta', label: 'შუამთა' },
    { value: 'shuakhevi', label: 'შუახევი' },
    { value: 'chakvi', label: 'ჩაქვი' },
    { value: 'chokhatauri', label: 'ჩოხატაური' },
    { value: 'chkhorotsqu', label: 'ჩხოროწყუ' },
    { value: 'tsageri', label: 'ცაგერი' },
    { value: 'tsemi', label: 'ცემი' },
    { value: 'tsikhisdziri', label: 'ციხისძირი' },
    { value: 'tskhvarichamia', label: 'ცხვარიჭამია' },
    { value: 'tskhinvali', label: 'ცხინვალი' },
    { value: 'tsalenjikha', label: 'წალენჯიხა' },
    { value: 'tsalka', label: 'წალკა' },
    { value: 'tsaghveri', label: 'წაღვერი' },
    { value: 'tserovani', label: 'წეროვანი' },
    { value: 'tsilkani', label: 'წილკანი' },
    { value: 'tsinandali', label: 'წინანდალი' },
    { value: 'tsitsamuri', label: 'წიწამური' },
    { value: 'tsnori', label: 'წნორი' },
    { value: 'tsqaltubo', label: 'წყალტუბო' },
    { value: 'chiatura', label: 'ჭიათურა' },
    { value: 'choporti', label: 'ჭოპორტი' },
    { value: 'kharagauli', label: 'ხარაგაული' },
    { value: 'khashmi', label: 'ხაშმი' },
    { value: 'khashuri', label: 'ხაშური' },
    { value: 'khevsureti', label: 'ხევსურეთი' },
    { value: 'khelvachauri', label: 'ხელვაჩაური' },
    { value: 'khobi', label: 'ხობი' },
    { value: 'khoni', label: 'ხონი' },
    { value: 'khulo', label: 'ხულო' },
    { value: 'kachreti', label: 'კაჭრეთი' },
    { value: 'java', label: 'ჯავა' },
];

export const districtOptions = [
    { value: 'apkhazeti', label: 'აფხაზეთი' },
    { value: 'achara', label: 'აჭარა' },
    { value: 'guria', label: 'გურია' },
    { value: 'imereti', label: 'იმერეთი' },
    { value: 'kakheti', label: 'კახეთი' },
    { value: 'mtskhetamtianeti', label: 'მცხეთა-მთიანეთი' },
    { value: 'rachalechkhumi', label: 'რაჭა-ლეჩხუმი და ქვემო სვანეთი' },
    { value: 'samegrelozemosvaneti', label: 'სამეგრელო-ზემო სვანეთი' },
    { value: 'samtskhejavakheti', label: 'სამცხე-ჯავახეთი' },
    { value: 'kvemokartli', label: 'ქვემო ქართლი' },
    { value: 'shidakartli', label: 'შიდა ქართლი' },
];

export const municipalOptions = [
    { value: 'abasha', label: 'აბაშის მუნიციპალიტეტი' },
    { value: 'adigeni', label: 'ადიგენის მუნიციპალიტეტი' },
    { value: 'khulo', label: 'ხულოს მუნიციპალიტეტი' },
    { value: 'ambrolauri', label: 'ამბროლაურის მუნიციპალიტეტი' },
    { value: 'aspindza', label: 'ასპინძის მუნიციპალიტეტი' },
    { value: 'apkhazeti', label: 'აფხაზეთის ავტონომიური რესპუბლიკა' },
    { value: 'khoni', label: 'ხონის მუნიციპალიტეტი' },
    { value: 'akhalkalaki', label: 'ახალქალაქის მუნიციპალიტეტი' },
    { value: 'khobi', label: 'ხობის მუნიციპალიტეტი' },
    { value: 'khelvachauri', label: 'ხელვაჩაურის მუნიციპალიტეტი' },
    { value: 'akhaltsikhe', label: 'ახალციხის მუნიციპალიტეტი' },
    { value: 'khashuri', label: 'ხაშურის მუნიციპალიტეტი' },
    { value: 'akhmeta', label: 'ახმეტის მუნიციპალიტეტი' },
    { value: 'baghdati', label: 'ბაღდათის მუნიციპალიტეტი' },
    { value: 'bolnisi', label: 'ბოლნისის მუნიციპალიტეტი' },
    { value: 'borjomi', label: 'ბორჯომის მუნიციპალიტეტი' },
    { value: 'kharagauli', label: 'ხარაგაულის მუნიციპალიტეტი' },
    { value: 'gardabni', label: 'გარდაბნის მუნიციპალიტეტი' },
    { value: 'gori', label: 'გორის მუნიციპალიტეტი' },
    { value: 'tsqaltubo', label: 'წყალტუბოს მუნიციპალიტეტი' },
    { value: 'tsalka', label: 'წალკის მუნიციპალიტეტი' },
    { value: 'gurjaani', label: 'გურჯაანის მუნიციპალიტეტი' },
    { value: 'tsalenjikha', label: 'წალენჯიხის მუნიციპალიტეტი' },
    { value: 'dedoplistsqaro', label: 'დედოფლისწყაროს მუნიციპალიტეტი' },
    { value: 'dmanisi', label: 'დმანისის რაიონი' },
    { value: 'tskhinvali', label: 'ცხინვალის რაიონი' },
    { value: 'tsageri', label: 'ცაგერის მუნიციპალიტეტი' },
    { value: 'chkhorotsqu', label: 'ჩხოროწყუს მუნიციპალიტეტი' },
    { value: 'vani', label: 'ვანის მუნიციპალიტეტი' },
    { value: 'zestaponi', label: 'ზესტაფონის მუნიციპალიტეტი' },
    { value: 'zugdidi', label: 'ზუგდიდის მუნიციპალიტეტი' },
    { value: 'chokhatauri', label: 'ჩოხატაურის მუნიციპალიტეტი' },
    { value: 'shuakhevi', label: 'შუახევის მუნიციპალიტეტი' },
    { value: 'tetritsqaro', label: 'თეთრიწყაროს მუნიციპალიტეტი' },
    { value: 'telavi', label: 'თელავის მუნიციპალიტეტი' },
    { value: 'qvareli', label: 'ყვარლის მუნიციპალიტეტი' },
    { value: 'terjola', label: 'თერჯოლის მუნიციპალიტეტი' },
    { value: 'tianeti', label: 'თიანეთის მუნიციპალიტეტი' },
    { value: 'qazbegi', label: 'ყაზბეგის მუნიციპალიტეტი' },
    { value: 'kutaisi', label: 'ქუთაისის მუნიციპალიტეტი' },
    { value: 'kobuleti', label: 'ქობულეთის მუნიციპალიტეტი' },
    { value: 'kaspi', label: 'კასპის მუნიციპალიტეტი' },
    { value: 'lagodekhi', label: 'ლაგოდეხის მუნიციპალიტეტი' },
    { value: 'keda', label: 'ქედის მუნიციპალიტეტი' },
    { value: 'lanchkhuti', label: 'ლანჩხუთის მუნიციპალიტეტი' },
    { value: 'lentekhi', label: 'ლენტეხის მუნიციპალიტეტი' },
    { value: 'kareli', label: 'ქარელის მუნიციპალიტეტი' },
    { value: 'marneuli', label: 'მარნეულის მუნიციპალიტეტი' },
    { value: 'tqibuli', label: 'ტყიბულის მუნიციპალიტეტი' },
    { value: 'martvili', label: 'მარტვილის მუნიციპალიტეტი' },
    { value: 'sighnaghi', label: 'სიღნაღის მუნიციპალიტეტი' },
    { value: 'senaki', label: 'სენაკის რაიონი' },
    { value: 'sachkhere', label: 'საჩხერის რაიონი' },
    { value: 'mestia', label: 'მესტიის მუნიციპალიტეტი' },
    { value: 'samtredia', label: 'სამტრედიის მუნიციპალიტეტი' },
    { value: 'sagarejo', label: 'საგარეჯოს მუნიციპალიტეტი' },
    { value: 'oni', label: 'ონის რაიონი' },
    { value: 'ozurgeti', label: 'ოზურგეთის მუნიციპალიტეტი' },
    { value: 'ninotsminda', label: 'ნინოწმინდის რაიონი' },
    { value: 'mtskheta', label: 'მცხეთის მუნიციპალიტეტი' },
    { value: 'akhalgori', label: 'ახალგორის მუნიციპალიტეტი' },
];

export const areaTypes = [
    { value: 'commercial', label: 'სავაჭრო' },
    { value: 'warehouse', label: 'სასაწყობე' },
    { value: 'industrialspace', label: 'საწარმოო ფართი' },
    { value: 'foodestablishment', label: 'კვების ობიექტი' },
    { value: 'special', label: 'სპეციალური' },
    { value: 'kiosk', label: 'ჯიხური' },
    { value: 'pavilion', label: 'პავილიონი' },
    { value: 'basement', label: 'სარდაფი' },
    { value: 'semibasement', label: 'ნახევარ სარდაფი' },
    { value: 'garage', label: 'ავტოფარეხი' },
    { value: 'autoservice', label: 'ავტო სერვისი' },
    { value: 'carwash', label: 'ავტო სამრეცხაო' },
    { value: 'businessyard', label: 'საქმიანი ეზო' },
    { value: 'wholebuilding', label: 'მთლიანი შენობა' },
    { value: 'newlybuiltbuilding', label: 'ახალი აშენებული კორპუსი' },
    { value: 'oldbuilding', label: 'ძველი აშენებული კორპუსი' },
    { value: 'universal', label: 'უნივერსალური' },
];

export const landTypes = [
    { value: 'agricultural', label: 'სასოფლო სამეურნეო' },
    { value: 'nonagricultural', label: 'არასასოფლო სამეურნეო' },
    { value: 'commercial', label: 'კომერციული' },
    { value: 'special', label: 'სპეციალური' },
    { value: 'investmentconstruction', label: 'საინვესტიციო მშენებლობისთვის' },
    { value: 'arableland', label: 'სახნავ სათესი' },
];

export const functgionZones = [
    { value: 'sz', label: 'სზ (1-6)' },
    { value: 'apz', label: 'APZ' },
    { value: 'grg', label: 'GRG' },
];

export const agriculturalTypes = [
    { value: 'arable', label: 'სახნავი' },
    { value: 'hayfield', label: 'სათიბი' },
    { value: 'pasture', label: 'საძოვარი' },
    { value: 'orchard', label: 'საბაღე' },
    { value: 'vegetablegarden', label: 'საბოსტნე' },
    { value: 'homestead', label: 'საკარმიდამო' },
    { value: 'forestry', label: 'სატყეო' },
];

export const hotelStars = [
    { value: '1star', label: '1 ვარსკვლავი' },
    { value: '2star', label: '2 ვარსკვლავი' },
    { value: '3star', label: '3 ვარსკვლავი' },
    { value: '4star', label: '4 ვარსკვლავი' },
    { value: '5star', label: '5 ვარსკვლავი' },
];

export const eatings = [
    { value: 'singleuse', label: 'ერთჯერადი' },
    { value: 'doubleuse', label: 'ორჯერადი' },
    { value: 'tripleuse', label: 'სამჯერადი' },
];

export const businesscenterClasses = [
    { value: 'aclass', label: 'A კლასი' },
    { value: 'aaclass', label: 'AA კლასი' },
    { value: 'bclass', label: 'B კლასი' },
    { value: 'cclass', label: 'C კლასი' },
    { value: 'withoutclass', label: 'კლასის გარეშე' },
];

export const businessIndustryes = [
    { value: 'manufacturingfactory', label: 'წარმოება ქარხანა' },
    { value: 'foodestablishment', label: 'კვების ობიექტი' },
    { value: 'equipmentmachines', label: 'ტექნიკა დანადგარები' },
    { value: 'hotel', label: 'სასტუმრო' },
    { value: 'gasstation', label: 'ბენზინგასამართი სადგური' },
    { value: 'gasstationlicense', label: 'ბენზინგასამართი სადგურის ლიცენზია' },
    { value: 'carwash', label: 'ავტოსამრეცხაო' },
    { value: 'shop', label: 'მაღაზია' },
    { value: 'operatingbusiness', label: 'მოქმედი ბიზნესი' },
    { value: 'construction', label: 'მშენებლობა' },
    { value: 'autopartsbusiness', label: 'ავტონაწილების ბიზნესი' },
    { value: 'furniturebusiness', label: 'ავეჯის ბიზნესი' },
    { value: 'investmentproject', label: 'საინვესტიციო პროექტი' },
    { value: 'buildingmaterials', label: 'სამშენებლო მასალები' },
    { value: 'other', label: 'სხვა' },
];

export const owners = [
    { value: 'individual', label: 'ფიზიკური პირი' },
    { value: 'smallbusiness', label: 'მცირე მეწარმე' },
    { value: 'legalentity', label: 'იურიდიული პირი' },
];

export const ownerTypes = [
    { value: 'owner', label: 'მესაკუთრე' },
    { value: 'lease', label: 'იჯარა' },
    { value: 'legalentity', label: 'იურიდიული პირი' },
];

export const actives = [
    { value: 'yes', label: 'კი' },
    { value: '', label: 'არა' },
];

export const farmTypes = [
    { value: 'cattle', label: 'მსხვილფეხა' },
    { value: 'poultryfarming', label: 'მეფრინველეობა' },
    { value: 'dairyfarm', label: 'რძის ფერმა' },
    { value: 'greenhouse', label: 'სათბური' },
    { value: 'winemaking', label: 'მეღვინეობა' },
    { value: 'vineyard', label: 'ვენახი' },
    { value: 'fruitorchard', label: 'ხეხილი-ბაღი' },
    { value: 'vegetables', label: 'ბოსტნეული' },
    { value: 'berries', label: 'კენკროვანი' },
    { value: 'hazelnuts', label: 'თხილის' },
    { value: 'walnuts', label: 'კაკლის' },
    { value: 'fishfarm', label: 'საკალმახე' },
    { value: 'incubator', label: 'ინკუბატორი' },
    { value: 'storagefridge', label: 'შესანახი მაცივარი' },
    { value: 'breedingfarm', label: 'საჯიშე ფერმერული მეურნეობა' },
    { value: 'other', label: 'სხვა' },
];

export const farmLandTypes = [
    { value: 'agricultural', label: 'სასოფლო სამეურნეო' },
    { value: 'nonagricultural', label: 'არასასოფლო სამეურნეო' },
    { value: 'commercial', label: 'კომერციული' },
    { value: 'special', label: 'სპეციალური' },
    { value: 'investmentconstruction', label: 'საინვესტიციო მშენებლობისთვის' },
    { value: 'arableland', label: 'სახნავ სათესი' },
];

export const farmAgriculturalTypes = [
    { value: 'arable', label: 'სახნავი' },
    { value: 'hayfield', label: 'სათიბი' },
    { value: 'pasture', label: 'საძოვარი' },
    { value: 'orchard', label: 'საბაღე' },
    { value: 'vegetablegarden', label: 'საბოსტნე' },
    { value: 'homestead', label: 'საკარმიდამო' },
    { value: 'forestry', label: 'სატყეო' },
];