import React, { useState, useEffect } from "react";
import { useAppContext } from "./../../components/Context";

//import tags
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y } from "swiper/modules";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

//import resources
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faTableCellsLarge,
  faHeart,
  faLocationCrosshairs,
  faBed,
  faDoorOpen,
  faLayerGroup,
  faChevronDown,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHeart as faHeartRegular,
  faClone,
} from "@fortawesome/free-regular-svg-icons";

import "swiper/css";
import "swiper/css/navigation";

import Noproperty from "../../assets/image/property.jpg";

//api
import { get_property } from "../../api/Api";

function Main() {
  const baseUrl = "https://reluxapi.myweb.com.ge";

  const { getenumbykey, setFavoritesCount } = useAppContext();

  const { id, slug } = useParams();

  const [property, setProperty] = useState(false);
  const [toggleView, setToggleView] = useState("grid");
  const [favorites, setFavorites] = useState([]);
  const [message, setMessage] = useState("");
  const [currentCurrency, setCurrentCurrency] = useState("GEL");
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [totalPages, setTotalPages] = useState(null);

  //filter
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [queryParams, setQueryParams] = useState({page: 1});

  const [sortBy, setSortBy] = useState("");
  const [page, setPage] = useState(1);
  const [transactionType, setTransactionType] = useState("");
  

  //build serchparams

 //on ready function set filter parameters values

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const sortParam = queryParams.get("sort") || "";
    const pageParam = parseInt(queryParams.get("page")) || 1;
    const transactionParam = queryParams.get("transaction_type") || "";

    setPage(pageParam);
    setSortBy(sortParam);
    setTransactionType(transactionParam);
    setIsInitialRender(false);
    
  }, []);

  //update query params on change filters
  useEffect(() => {
    if (!isInitialRender) {
      updateQueryParams("sort", sortBy);
      updateQueryParams("page", page.toString());
      updateQueryParams("transaction_type", transactionType);
    }
  }, [sortBy, page, transactionType]);

  //filter function to add items to filter array and send to get parameter in url
  const updateQueryParams = (key, value) => {
    setQueryParams((prevParams) => {
      const updatedParams = { ...prevParams };

      if (value === "") {
        delete updatedParams[key];
      } else {
        updatedParams[key] = value;
      }

      const searchParams = new URLSearchParams(updatedParams);
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      
      window.history.pushState(null, "", newUrl);
      
      return updatedParams;
    });
  };


  //total pages

  useEffect(() => {
    if (typeof property == "object" && totalPages == null) {
      setTotalPages(property?.total / property?.per_page);
    }
  }, [property]);


  //change view of property
  const toggleViewHandler = () => {
    setToggleView((prevView) => {
      const newView = prevView === "grid" ? "list" : "grid";
      localStorage.setItem("view", newView);
      return newView;
    });
  };

  //get view from localstorage if exist

  useEffect(() => {
    if (localStorage.hasOwnProperty("view")) {
      setToggleView(localStorage.getItem("view"));
    }
  }, []);


  //get favorites if saved

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    setFavorites(storedFavorites);
  }, []);

  //set favorites
  const toggleFavorite = (id) => {
    let updatedFavorites;

    if (favorites.includes(id)) {
      updatedFavorites = favorites.filter((favId) => favId !== id);
    } else {
      updatedFavorites = [...favorites, id];
    }

    setFavorites(updatedFavorites);

    localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
  };

  //toggle currency
  const toggleCurrency = (currency) => {
    setCurrentCurrency(currency);
  };

  //toggle visibility
  const toggleVisibility = () => {
    setIsVisibleFilter(!isVisibleFilter);
  };

  //fetch api data
  useEffect(() => {
    const fetchData = async () => {
      try {
        get_property(setProperty, queryParams);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (Object.keys(queryParams).length !== 0) {
      fetchData();
    }
  }, [queryParams]);


  //set favorites count context
  useEffect(() => {
    setFavoritesCount(null);
  }, [favorites]);

  return (
    <>
      <div className="main">
        <section className="productSection">
          <Container>
            <Row>
              <Col xxl={12}>
                <div className="productCard">
                  <div className="filterInside">
                    <div className="singleFilterBox">
                      <div className="filterBoxMain">awd</div>

                      <div
                        className="singleFilterBoxShow"
                        onClick={toggleVisibility}
                      >
                        {isVisibleFilter ? "show less" : "show more"}
                      </div>
                      {isVisibleFilter && (
                        <div className="singleFilterBoxHidden">awd</div>
                      )}
                    </div>
                  </div>
                </div>
              </Col>

              <Col xxl={12}>
                <div className="productCard">
                  <div className="productCardTopSortBox">
                    <div className="productCardTopSortBoxLeft">
                      <h4>
                        მოიძებნა{" "}
                        <span>
                          {typeof property === "object" ? property.total : 0}
                        </span>{" "}
                        განცხადება
                      </h4>
                    </div>
                    <div className="productCardTopSortBoxRight">
                      <div className="form-group sortBYButton">
                        <select
                          value={sortBy}
                          onChange={(event) => setSortBy(event.target.value)}
                          className="form-control"
                        >
                          <option value="">დალაგება</option>
                          <option value="price_gel~asc">ფასი ზრდადობით</option>
                          <option value="price_gel~desc">
                            ფასი კლებადობით
                          </option>
                          <option value="created_at~asc">
                            თარიღი ზრდადობით
                          </option>
                          <option value="created_at~desc">
                            თარიღი კლებადობით
                          </option>
                        </select>
                        <FontAwesomeIcon icon={faChevronDown} />
                      </div>

                      <div
                        className="changeViewButton"
                        onClick={() => toggleViewHandler()}
                      >
                        {toggleView == "grid" ? (
                          <FontAwesomeIcon
                            title="Grid view"
                            icon={faTableCellsLarge}
                          />
                        ) : (
                          <FontAwesomeIcon title="List view" icon={faList} />
                        )}
                      </div>
                    </div>
                  </div>
                  <Row>
                    {typeof property === "object" ? (
                      property.data.map(function (item) {
                        return (
                          <div
                            className={
                              toggleView == "grid"
                                ? "gridView col-xxl-3"
                                : "listView col-xxl-12"
                            }
                            key={item.id}
                          >
                            <div className="singleProductGridBox">
                              {message && (
                                <div className="addedToFavourites">
                                  <h4>{message}</h4>
                                </div>
                              )}
                              <div className="singleProductGridBoxImageBox">
                                <Link to={`/property/details/` + item.id}>
                                  <Swiper
                                    modules={[Navigation, A11y]}
                                    spaceBetween={5}
                                    slidesPerView={1}
                                    navigation
                                    scrollbar={{ draggable: true }}
                                  >
                                    {item.image1 && item.image1 != null ? (
                                      <SwiperSlide>
                                        <div className="singleProductGridBoxImage">
                                          <Image
                                            src={`${baseUrl}/storage/property/${item.image_folder}/thumbnail/${item.image1}`}
                                            alt={`${
                                              typeof item === "object" &&
                                              item?.transaction_type != null
                                                ? getenumbykey(
                                                    parse(item.transaction_type)
                                                  )
                                                : ""
                                            } ${
                                              typeof item === "object" &&
                                              item?.property_type != null
                                                ? getenumbykey(
                                                    parse(item.property_type)
                                                  )
                                                : ""
                                            }`}
                                          />
                                        </div>
                                      </SwiperSlide>
                                    ) : (
                                      <SwiperSlide>
                                        <div className="singleProductGridBoxImage">
                                          <Image src={Noproperty} alt="Relux" />
                                        </div>
                                      </SwiperSlide>
                                    )}
                                  </Swiper>
                                </Link>
                                {item.statement_type && (
                                  <div
                                    className={`singleProductGridTag ${
                                      item.statement_type === "exclusive"
                                        ? "exclusiveAd"
                                        : "reluxAd"
                                    }`}
                                  >
                                    <Link to="/">
                                      <h4>
                                        {item?.statement_type != null
                                          ? getenumbykey(
                                              parse(item.statement_type)
                                            )
                                          : ""}
                                      </h4>
                                    </Link>
                                  </div>
                                )}
                                <div
                                  className="singleProductGridFavourite"
                                  onClick={() => toggleFavorite(item.id)}
                                >
                                  {favorites.includes(item.id) ? (
                                    <FontAwesomeIcon icon={faHeart} />
                                  ) : (
                                    <FontAwesomeIcon icon={faHeartRegular} />
                                  )}
                                </div>
                              </div>
                              <div className="singleProductGridBoxContant">
                                <div className="singleProductGridPrice">
                                  {["GEL", "USD", "EUR"].includes(
                                    currentCurrency
                                  ) && (
                                    <h4>
                                      {item?.[
                                        `price_${currentCurrency.toLowerCase()}`
                                      ] != null
                                        ? item[
                                            `price_${currentCurrency.toLowerCase()}`
                                          ]
                                        : ""}
                                      <span>{currentCurrency}</span>
                                    </h4>
                                  )}
                                  <div className="priceBoxGrid">
                                    {["GEL", "USD", "EUR"].map((currency) => (
                                      <div
                                        key={currency}
                                        className={`singlePrice ${
                                          currentCurrency === currency
                                            ? "active"
                                            : ""
                                        }`}
                                        onClick={() => toggleCurrency(currency)}
                                      >
                                        <p>{currency}</p>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div className="propertyGridTags">
                                  <div className="singleTag">
                                    {item?.property_type != null
                                      ? getenumbykey(parse(item.property_type))
                                      : ""}
                                  </div>
                                  <div className="singleTag">
                                    {item?.city != null
                                      ? getenumbykey(parse(item.city))
                                      : ""}
                                  </div>
                                </div>
                                <Link to={`/property/details/` + item.id}>
                                  <h1>
                                    {item?.transaction_type != null
                                      ? getenumbykey(
                                          parse(item.transaction_type)
                                        )
                                      : ""}{" "}
                                    {item?.property_type != null
                                      ? getenumbykey(parse(item.property_type))
                                      : ""}
                                  </h1>
                                </Link>
                                {toggleView == "list" ? (
                                  <div className="listItemDescription">
                                    {item?.translations[0]?.text != null
                                      ? parse(item?.translations[0]?.text)
                                      : ""}
                                  </div>
                                ) : null}

                                <div className="singlePropertyGridLocation">
                                  <FontAwesomeIcon
                                    icon={faLocationCrosshairs}
                                  />
                                  <p>
                                    {item?.address != null
                                      ? item.address.length > 50
                                        ? item.address
                                            .substring(0, 50)
                                            .replace(/<[^>]*>/g, "") + "..."
                                        : item.address.replace(/<[^>]*>/g, "")
                                      : ""}
                                  </p>
                                </div>
                                <div className="singlePropertyListMiniBox">
                                  {(() => {
                                    switch (item?.property_type) {
                                      case "land":
                                        return (
                                          <div
                                            className="singlePropertyGridMini"
                                            title="ფართი"
                                          >
                                            <FontAwesomeIcon icon={faClone} />:
                                            <span>
                                              {item?.area != null
                                                ? item.area
                                                : ""}{" "}
                                              მ<sup>2</sup>
                                            </span>
                                          </div>
                                        );
                                      case "apartment":
                                        return (
                                          <>
                                            <div
                                              className="singlePropertyGridMini"
                                              title="საძინებელი"
                                            >
                                              <FontAwesomeIcon icon={faBed} />:
                                              <span>
                                                {item?.bedrooms != null
                                                  ? item.bedrooms
                                                  : ""}
                                              </span>
                                            </div>
                                            <div
                                              className="singlePropertyGridMini"
                                              title="ოთახი"
                                            >
                                              <FontAwesomeIcon
                                                icon={faDoorOpen}
                                              />
                                              :
                                              <span>
                                                {item?.rooms != null
                                                  ? item.rooms
                                                  : ""}
                                              </span>
                                            </div>
                                            <div
                                              className="singlePropertyGridMini"
                                              title="სართული"
                                            >
                                              <FontAwesomeIcon
                                                icon={faLayerGroup}
                                              />
                                              :
                                              <span>
                                                {item?.floor != null
                                                  ? item.floor
                                                  : ""}
                                              </span>
                                            </div>
                                            <div
                                              className="singlePropertyGridMini"
                                              title="ფართი"
                                            >
                                              <FontAwesomeIcon icon={faClone} />
                                              :
                                              <span>
                                                {item?.area != null
                                                  ? item.area
                                                  : ""}{" "}
                                                მ<sup>2</sup>
                                              </span>
                                            </div>
                                          </>
                                        );
                                      case "business":
                                        return (
                                          <>
                                            <div
                                              className="singlePropertyGridMini"
                                              title="ფართი"
                                            >
                                              <FontAwesomeIcon icon={faClone} />
                                              :
                                              <span>
                                                {item?.area != null
                                                  ? item.area
                                                  : ""}{" "}
                                                მ<sup>2</sup>
                                              </span>
                                            </div>
                                            <div
                                              className="singlePropertyGridMini"
                                              title="ოთახი"
                                            >
                                              <FontAwesomeIcon
                                                icon={faDoorOpen}
                                              />
                                              :
                                              <span>
                                                {item?.rooms != null
                                                  ? item.rooms
                                                  : ""}
                                              </span>
                                            </div>
                                          </>
                                        );
                                      default:
                                        return null;
                                    }
                                  })()}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <>
                        <Col xl={3}>
                          <div className="singleProductGridBox">
                            <div className="singleProductGridBoxImageBox">
                              <Skeleton height={240} />
                            </div>
                            <div className="singleProductGridBoxContant">
                              <h1 className="mt-3">
                                <Skeleton height={25} />
                              </h1>
                              <Skeleton className="mt-3" height={20} />
                              <Skeleton className="mt-3" height={20} />
                            </div>
                          </div>
                        </Col>
                        <Col xl={3}>
                          <div className="singleProductGridBox">
                            <div className="singleProductGridBoxImageBox">
                              <Skeleton height={240} />
                            </div>
                            <div className="singleProductGridBoxContant">
                              <h1 className="mt-3">
                                <Skeleton height={25} />
                              </h1>
                              <Skeleton className="mt-3" height={20} />
                              <Skeleton className="mt-3" height={20} />
                            </div>
                          </div>
                        </Col>
                        <Col xl={3}>
                          <div className="singleProductGridBox">
                            <div className="singleProductGridBoxImageBox">
                              <Skeleton height={240} />
                            </div>
                            <div className="singleProductGridBoxContant">
                              <h1 className="mt-3">
                                <Skeleton height={25} />
                              </h1>
                              <Skeleton className="mt-3" height={20} />
                              <Skeleton className="mt-3" height={20} />
                            </div>
                          </div>
                        </Col>
                        <Col xl={3}>
                          <div className="singleProductGridBox">
                            <div className="singleProductGridBoxImageBox">
                              <Skeleton height={240} />
                            </div>
                            <div className="singleProductGridBoxContant">
                              <h1 className="mt-3">
                                <Skeleton height={25} />
                              </h1>
                              <Skeleton className="mt-3" height={20} />
                              <Skeleton className="mt-3" height={20} />
                            </div>
                          </div>
                        </Col>
                      </>
                    )}

                    <div className="col-xxl-12 mt-5">
                      <div className="pagination">
                        <button
                          onClick={
                            property.current_page > 1
                              ? () => setPage(property.current_page - 1)
                              : null
                          }
                          disabled={property.current_page <= 1}
                          className="pagination-button paginatePrev"
                        >
                          <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                        <div className="pagination-pages paginateMiddle">
                          {typeof totalPages === "number" && totalPages > 0
                            ? Array.from(
                                { length: Math.ceil(totalPages) },
                                (_, index) => (
                                  <div
                                    key={index}
                                    onClick={() => setPage(index + 1)}
                                    className={
                                      "pagination-page paginateSingle " +
                                      (index + 1 === property.current_page
                                        ? "active"
                                        : "")
                                    }
                                  >
                                    {index + 1}
                                  </div>
                                )
                              )
                            : ""}
                        </div>
                        <button
                          className="pagination-button paginateNext"
                          onClick={
                            property.current_page < Math.ceil(totalPages)
                              ? () => setPage(property.current_page + 1)
                              : null
                          }
                          disabled={property.current_page > Math.ceil(totalPages)}
                        >
                          <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
}

export default Main;
