import React, { useState, useEffect } from "react";
import { useAppContext } from "../components/Context";

//import tags
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y } from "swiper/modules";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

//import resources
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faHeart,
  faLocationCrosshairs,
  faBed,
  faDoorOpen,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHeart as faHeartRegular,
  faClone,
} from "@fortawesome/free-regular-svg-icons";

import "swiper/css";
import "swiper/css/navigation";

import Noproperty from "../../src/assets/image/property.jpg";

//api
import { get_favorites } from "../api/Api";

function Main() {
  const baseUrl = "https://reluxapi.myweb.com.ge";
  const { getenumbykey, setFavoritesCount } = useAppContext();
  const { id, slug } = useParams();
  const [favorites, setFavorites] = useState([]);
  const [message, setMessage] = useState("");
  const [currentCurrency, setCurrentCurrency] = useState("GEL");
  const [property, setProperty] = useState(false);

  const toggleCurrency = (currency) => {
    setCurrentCurrency(currency);
  };

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    setFavorites(storedFavorites);
  }, []);

  const toggleFavorite = (id) => {
    let updatedFavorites;

    if (favorites.includes(id)) {
      updatedFavorites = favorites.filter((favId) => favId !== id);
    } else {
      updatedFavorites = [...favorites, id];
    }

    setFavorites(updatedFavorites);

    localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (localStorage.hasOwnProperty("favorites")) {
          const formData = {
            ids: localStorage.getItem("favorites"),
          };

          get_favorites(setProperty, formData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (!property) {
      fetchData();
    }
  }, [property]);

  useEffect(() => {
    setProperty(false);
    setFavoritesCount(null);
  }, [favorites]);

  return (
    <div className="main">
      <section className="breadcumbSection" id="sectionBreadCumb">
        <Container>
          <Row>
            <Col xxl={12}>
              <div className="breadcumb">
                <div className="breadcumbLeft">
                  <h1>ფავორიტები</h1>
                </div>
                <div className="breadcumbRight">
                  <ul>
                    <li>
                      <Link to="/">მთავარი</Link>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </li>
                    <li>ფავორიტები</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="contactSection">
        <Container>
          <Row>
            <Col xxl={12}>
              <div className="profileCard">
                <h4 className="profileEditBoxTitle">
                  ჩემი ფავორიტი განცხადებები
                </h4>
                <Row>
                  {typeof property === "object" ? (
                    property.map(function (item) {
                      return (
                        <Col xl={3} key={item.id}>
                          <div className="singleProductGridBox">
                            {message && (
                              <div className="addedToFavourites">
                                <h4>{message}</h4>
                              </div>
                            )}
                            <div className="singleProductGridBoxImageBox">
                              <Link to={`/property/details/` + item.id}>
                                <Swiper
                                  modules={[Navigation, A11y]}
                                  spaceBetween={5}
                                  slidesPerView={1}
                                  navigation
                                  scrollbar={{ draggable: true }}
                                >
                                  {item.image1 && item.image1 != null ? (
                                    <SwiperSlide>
                                      <div className="singleProductGridBoxImage">
                                        <Image
                                          src={`${baseUrl}/storage/property/${item.image_folder}/thumbnail/${item.image1}`}
                                          alt={`${
                                            typeof item === "object" &&
                                            item?.transaction_type != null
                                              ? getenumbykey(
                                                  parse(item.transaction_type)
                                                )
                                              : ""
                                          } ${
                                            typeof item === "object" &&
                                            item?.property_type != null
                                              ? getenumbykey(
                                                  parse(item.property_type)
                                                )
                                              : ""
                                          }`}
                                        />
                                      </div>
                                    </SwiperSlide>
                                  ) : (
                                    <SwiperSlide>
                                      <div className="singleProductGridBoxImage">
                                        <Image src={Noproperty} alt="Relux" />
                                      </div>
                                    </SwiperSlide>
                                  )}
                                </Swiper>
                              </Link>
                              {item.statement_type && (
                                <div
                                  className={`singleProductGridTag ${
                                    item.statement_type === "exclusive"
                                      ? "exclusiveAd"
                                      : "reluxAd"
                                  }`}
                                >
                                  <Link to="/">
                                    <h4>
                                      {item?.statement_type != null
                                        ? getenumbykey(
                                            parse(item.statement_type)
                                          )
                                        : ""}
                                    </h4>
                                  </Link>
                                </div>
                              )}
                              <div
                                className="singleProductGridFavourite"
                                onClick={() => toggleFavorite(item.id)}
                              >
                                {favorites.includes(item.id) ? (
                                  <FontAwesomeIcon icon={faHeart} />
                                ) : (
                                  <FontAwesomeIcon icon={faHeartRegular} />
                                )}
                              </div>
                            </div>
                            <div className="singleProductGridBoxContant">
                              <div className="singleProductGridPrice">
                                {["GEL", "USD", "EUR"].includes(
                                  currentCurrency
                                ) && (
                                  <h4>
                                    {item?.[
                                      `price_${currentCurrency.toLowerCase()}`
                                    ] != null
                                      ? item[
                                          `price_${currentCurrency.toLowerCase()}`
                                        ]
                                      : ""}
                                    <span>{currentCurrency}</span>
                                  </h4>
                                )}
                                <div className="priceBoxGrid">
                                  {["GEL", "USD", "EUR"].map((currency) => (
                                    <div
                                      key={currency}
                                      className={`singlePrice ${
                                        currentCurrency === currency
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => toggleCurrency(currency)}
                                    >
                                      <p>{currency}</p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="propertyGridTags">
                                <div className="singleTag">
                                  {item?.property_type != null
                                    ? getenumbykey(parse(item.property_type))
                                    : ""}
                                </div>
                                <div className="singleTag">
                                  {item?.city != null
                                    ? getenumbykey(parse(item.city))
                                    : ""}
                                </div>
                              </div>
                              <Link to={`/property/details/` + item.id}>
                                <h1>
                                  {item?.transaction_type != null
                                    ? getenumbykey(parse(item.transaction_type))
                                    : ""}{" "}
                                  {item?.property_type != null
                                    ? getenumbykey(parse(item.property_type))
                                    : ""}
                                </h1>
                              </Link>
                              <div className="singlePropertyGridLocation">
                                <FontAwesomeIcon icon={faLocationCrosshairs} />
                                <p>
                                  {item?.address != null
                                    ? item.address.length > 50
                                      ? item.address
                                          .substring(0, 50)
                                          .replace(/<[^>]*>/g, "") + "..."
                                      : item.address.replace(/<[^>]*>/g, "")
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })
                  ) : (
                    <>
                      <Col xl={3}>
                        <div className="singleProductGridBox">
                          <div className="singleProductGridBoxImageBox">
                            <Skeleton height={240} />
                          </div>
                          <div className="singleProductGridBoxContant">
                            <h1 className="mt-3">
                              <Skeleton height={25} />
                            </h1>
                            <Skeleton className="mt-3" height={20} />
                            <Skeleton className="mt-3" height={20} />
                          </div>
                        </div>
                      </Col>
                      <Col xl={3}>
                        <div className="singleProductGridBox">
                          <div className="singleProductGridBoxImageBox">
                            <Skeleton height={240} />
                          </div>
                          <div className="singleProductGridBoxContant">
                            <h1 className="mt-3">
                              <Skeleton height={25} />
                            </h1>
                            <Skeleton className="mt-3" height={20} />
                            <Skeleton className="mt-3" height={20} />
                          </div>
                        </div>
                      </Col>
                      <Col xl={3}>
                        <div className="singleProductGridBox">
                          <div className="singleProductGridBoxImageBox">
                            <Skeleton height={240} />
                          </div>
                          <div className="singleProductGridBoxContant">
                            <h1 className="mt-3">
                              <Skeleton height={25} />
                            </h1>
                            <Skeleton className="mt-3" height={20} />
                            <Skeleton className="mt-3" height={20} />
                          </div>
                        </div>
                      </Col>
                      <Col xl={3}>
                        <div className="singleProductGridBox">
                          <div className="singleProductGridBoxImageBox">
                            <Skeleton height={240} />
                          </div>
                          <div className="singleProductGridBoxContant">
                            <h1 className="mt-3">
                              <Skeleton height={25} />
                            </h1>
                            <Skeleton className="mt-3" height={20} />
                            <Skeleton className="mt-3" height={20} />
                          </div>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Main;
